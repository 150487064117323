import React, { useRef, useEffect, useState } from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import { LiveProvider, LiveEditor, LiveError, LivePreview } from "react-live";
import Highlight, { defaultProps } from "prism-react-renderer";
import { IoMdCode, IoLogoGithub } from "react-icons/io";
import { IconContext } from "react-icons";

// Components
import SEO from "./SEO";

// Style
import styled, { css } from "styled-components";
import {
  neuzeitGroLight,
  fontSize,
  primary,
  mediaQueries
} from "../../styles/fonts";
import theme from "../../styles/theme";

const Title = styled.div`
  ${neuzeitGroLight}
  font-size: ${fontSize.xl};
  font-weight: 600;
  margin-bottom: 16px;

  @media (max-width: ${mediaQueries.mobile}) {
    font-size: ${fontSize.lg};
  }
`;

const H1 = styled.div`
  ${neuzeitGroLight}
  font-size: ${fontSize.lg};
  font-weight: 600;
  margin: 16px 0px;

  @media (max-width: ${mediaQueries.mobile}) {
    font-size: ${fontSize.md};
  }
`;

const H2 = styled.div`
  ${neuzeitGroLight}
  font-size: ${fontSize.md};
  font-weight: 600;
  margin: 16px 0px;

  @media (max-width: ${mediaQueries.mobile}) {
    font-size: ${fontSize.sm};
  }

`;

const OL = styled.ol`
  ${neuzeitGroLight}
  font-size: ${fontSize.xxs};
`;

export const A = styled.a`
  color: black;
  text-decoration: none;

  &:hover {
    color: ${primary};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Content = styled.div`
  ${neuzeitGroLight}
  font-size: ${fontSize.sm}
`;

const CodeWrapper = styled.div`
  height: 28px;
  overflow: hidden;
  transition: height 0.5s;
  margin-bottom: 8px;
  width: calc(100vw - 226px);
  max-width: 800px;

  @media (max-width: ${mediaQueries.tablet}) {
    width: calc(100vw - 64px);
  }

  ${props =>
    // 28px for the height of the button container
    // 32px for the padding

    props.showCode &&
    css`
      height: ${props.totalHeight + 28 + 32}px;
    `}
`;

const HighlighterWrapper = styled.div`
  width: calc(100vw - 226px);
  max-width: 800px;

  @media (max-width: ${mediaQueries.tablet}) {
    width: calc(100vw - 64px);
  }
`;

const highlightStyle = {
  overflow: "scroll",
  padding: 16,
  fontSize: 16,
  fontWeight: "normal"
};

const SyntaxHighlighterWrapper = props => {
  const codeRef = useRef(null);
  const [height, setHeight] = useState(0);
  const [codeIcon, setCodeIcon] = useState(false);
  const [githubIcon, setGithubIcon] = useState(false);
  const [showCode, setShowCode] = useState(false);

  useEffect(() => {
    if (codeRef.current) {
      setHeight(codeRef.current.clientHeight);
    }
  }, [codeRef]);

  const iconStyleHover = {
    color: `${primary}`,
    cursor: "pointer",
    fontSize: "28px",
    marginRight: "8px"
  };

  const iconStyle = {
    color: "black",
    cursor: "pointer",
    fontSize: "28px",
    marginRight: "8px"
  };

  const className = props.children.props.className || "";
  const matches = className.match(/language-(?<lang>.*)/);
  return (
    <CodeWrapper totalHeight={height} showCode={showCode}>
      <ButtonContainer>
        <IconContext.Provider
          value={{ style: codeIcon ? iconStyleHover : iconStyle }}
        >
          <IoMdCode
            onMouseEnter={() => setCodeIcon(true)}
            onMouseLeave={() => setCodeIcon(false)}
            onClick={() => setShowCode(!showCode)}
          />
        </IconContext.Provider>
        <a
          href={props.children.props.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconContext.Provider
            value={{ style: githubIcon ? iconStyleHover : iconStyle }}
          >
            <IoLogoGithub
              onMouseEnter={() => setGithubIcon(true)}
              onMouseLeave={() => setGithubIcon(false)}
            />
          </IconContext.Provider>
        </a>
      </ButtonContainer>
      <Highlight
        {...defaultProps}
        code={props.children.props.children.trim()}
        theme={theme}
        language={
          matches && matches.groups && matches.groups.lang
            ? matches.groups.lang
            : ""
        }
      >
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <pre
            className={className}
            style={{ ...style, ...highlightStyle }}
            ref={codeRef}
          >
            {tokens.map((line, i) => (
              <div {...getLineProps({ line, key: i })}>
                {line.map((token, key) => (
                  <span {...getTokenProps({ token, key })} />
                ))}
              </div>
            ))}
          </pre>
        )}
      </Highlight>
    </CodeWrapper>
  );
};

const SyntaxHighlighter = props => {
  const className = props.children.props.className || "";
  const matches = className.match(/language-(?<lang>.*)/);
  return (
    <HighlighterWrapper>
      <Highlight
        {...defaultProps}
        code={props.children.props.children.trim()}
        theme={theme}
        language={
          matches && matches.groups && matches.groups.lang
            ? matches.groups.lang
            : ""
        }
      >
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <pre className={className} style={{ ...style, ...highlightStyle }}>
            {tokens.map((line, i) => (
              <div {...getLineProps({ line, key: i })}>
                {line.map((token, key) => (
                  <span {...getTokenProps({ token, key })} />
                ))}
              </div>
            ))}
          </pre>
        )}
      </Highlight>
    </HighlighterWrapper>
  );
};

const LiveCode = props => (
  <LiveProvider code={props.children.props.children.trim()}>
    <LiveEditor />
    <LiveError />
    <LivePreview />
  </LiveProvider>
);

const components = {
  p: Content,
  h1: H1,
  h2: H2,
  ol: OL,
  a: A,
  pre: props => {
    if (props.children.props["react-live"]) {
      return <LiveCode {...props} />;
    } else if (props.children.props["no-wrapper"]) {
      return <SyntaxHighlighter {...props} />;
    } else {
      return <SyntaxHighlighterWrapper {...props} />;
    }
  }
};

export default ({ data, useSEO }) => {
  // Template used on index
  const post = data.mdx || data;
  return (
    <>
      {useSEO && <SEO content={post.frontmatter} />}
      <Title>{post.frontmatter.title}</Title>
      <MDXProvider components={components}>
        <MDXRenderer>{post.body}</MDXRenderer>
      </MDXProvider>
    </>
  );
};

export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        title
        excerpt
      }
    }
  }
`;
